<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
      :title="$t('page.shareholder.title')"
      @edit="$emit('edit')"
    >
      <p>{{ $t('page.shareholder.description') }}</p>
      <p>{{ $t('page.shareholder.upTo') }}</p>
      <professional-order-condition
        :order="storedModel.professionalOrder.order"
        page="shareholder"
      >
      </professional-order-condition>
      <b-button
        type="is-text is-anchor"
        @click="useDirectors"
      >
        {{ $t('page.shareholder.useDirectors') }}
      </b-button>
    </page-title>
    <div
      v-for="(shareholder, index) in model"
      :key="index + rerender"
      class="box"
    >
      <button
        v-if="isViewModeCreate && index !== 0 && index === model.length - 1"
        role="button"
        type="button"
        class="delete"
        tabindex="100"
        @click="removeShareholder(index)"
      >
      </button>
      <h3>{{ $t('page.shareholder.section') + ' ' + (index + 1) }}</h3>
      <b-field
        v-if="isViewModeCreate"
        class="mt-4"
      >
        <div>
          <b-radio
            v-model="shareholder.individual"
            :native-value="true"
            :name="`individual${index}`"
            @input="clearAllErrors(index)"
          >
            {{ $t('form.field.individual.individual') }}
          </b-radio>
          <b-radio
            v-model="shareholder.individual"
            :native-value="false"
            :name="`individual${index}`"
            @input="clearAllErrors(index)"
          >
            {{ $t('form.field.individual.legalPerson') }}
          </b-radio>
        </div>
      </b-field>
      <div v-if="shareholder.individual">
        <div class="columns">
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('firstname')"
              :type="getFieldType('firstname', index)"
              :message="getFieldMessage('firstname', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].firstname`)"
                v-model="shareholder.firstname"
                @blur="validateFirstname(index)"
                custom-class="page-focus-first"
                :name="`fname${index}`"
                autocomplete="given-name"
              >
              </b-input>
              <span v-else>{{ shareholder.firstname }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].firstname`"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('lastname')"
              :type="getFieldType('lastname', index)"
              :message="getFieldMessage('lastname', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].lastname`)"
                v-model="shareholder.lastname"
                @blur="validateLastname(index)"
                :name="`lname${index}`"
                autocomplete="family-name"
              >
              </b-input>
              <span v-else>{{ shareholder.lastname }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].lastname`"></revision-message>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('phone')"
              :type="getFieldType('phone', index)"
              :message="getFieldMessage('phone', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].phone`)"
                v-model="shareholder.phone"
                type="tel"
                @blur="validatePhone(index)"
                :name="`phone${index}`"
                autocomplete="tel"
              >
              </b-input>
              <span v-else>{{ shareholder.phone }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].phone`"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('birthdate')"
              :type="getFieldType('birthdate', index)"
              :message="getFieldMessage('birthdate', index)"
            >
              <b-datepicker
                v-if="isFieldEditable(`$.shareholder[${index}].birthdate`)"
                v-model="shareholder.birthdate"
                :max-date="$moment().toDate()"
                :date-formatter="formatDateWithSlashes"
                icon="calendar-day"
                trap-focus
                @blur="validateBirthdate(index)"
              >
              </b-datepicker>
              <span v-else>{{ formatBirthdate(shareholder.birthdate) }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].birthdate`"></revision-message>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="columns">
          <div class="column">
            <b-field
              :label="getFieldLabel('corporationname')"
              :type="getFieldType('corporationname', index)"
              :message="getFieldMessage('corporationname', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].corporationname`)"
                v-model="shareholder.corporationname"
                @blur="validateCorporationName(index)"
                :name="`organization${index}`"
                autocomplete="organization"
              >
              </b-input>
              <span v-else>{{ shareholder.corporationname }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].corporationname`"></revision-message>
          </div>
          <div class="column">
            <b-field
              :label="getFieldLabel('neq')"
              :type="getFieldType('neq', index)"
              :message="getFieldMessage('neq', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].neq`)"
                v-model="shareholder.neq"
                autocomplete="off"
              >
              </b-input>
              <span v-else>{{ shareholder.neq }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].neq`"></revision-message>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('contactname')"
              :type="getFieldType('contactname', index)"
              :message="getFieldMessage('contactname', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].contactname`)"
                v-model="shareholder.contactname"
                @blur="validateContactName(index)"
                :name="`contactname${index}`"
                autocomplete="given-name"
              >
              </b-input>
              <span v-else>{{ shareholder.contactname }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].contactname`"></revision-message>
          </div>
          <div class="column is-half">
            <b-field
              :label="getFieldLabel('contactphone')"
              :type="getFieldType('contactphone', index)"
              :message="getFieldMessage('contactphone', index)"
            >
              <b-input
                v-if="isFieldEditable(`$.shareholder[${index}].contactphone`)"
                v-model="shareholder.contactphone"
                type="tel"
                @blur="validateContactPhone(index)"
                :name="`phone${index}`"
                autocomplete="tel"
              >
              </b-input>
              <span v-else>{{ shareholder.contactphone }}</span>
            </b-field>
            <revision-message :path="`$.shareholder[${index}].contactphone`"></revision-message>
          </div>
        </div>
      </div>
      <section-address
        :ref="`shareholderAddress${index}`"
        :parent-path="`$.shareholder[${index}]`"
        :index="index"
        :model="shareholder"
      >
      </section-address>
      <b-field :label="isViewModeCreate ? '' : $t('page.shareholder.shares')">
        <div v-if="isFieldEditable(`$.shareholder[${index}].shares`)">
          <b-checkbox
            v-model="shareholder.isVotingShares"
            :disabled="hasOneShareholder"
            @input="updateShareDistribution"
          >
            {{ $t('page.shareholder.votingShares') }}
          </b-checkbox>
          <b-checkbox
            v-model="shareholder.isParticipatingShares"
            :disabled="hasOneShareholder"
            @input="updateShareDistribution"
          >
            {{ $t('page.shareholder.participatingShares') }}
          </b-checkbox>
        </div>
        <span v-else>
          {{
            (shareholder.isVotingShares ? $t('page.shareholder.voting') : '') +
            (shareholder.isVotingShares && shareholder.isParticipatingShares ? $t('page.shareholder.and') : '') +
            (shareholder.isParticipatingShares ? $t('page.shareholder.participating') : '')
          }}
        </span>
      </b-field>
    </div>
    <b-field class="has-text-centered">
      <b-button
        v-if="isViewModeCreate && model.length < 3"
        @click="addShareholder"
        type="is-text is-anchor"
      >
        {{ $t('page.shareholder.add') }}
      </b-button>
    </b-field>
    <b-message
      v-if="isMissingShareType"
      type="is-danger"
    >
      {{ $t('page.shareholder.missingShareType') }}
    </b-message>
  </fieldset>
</template>

<script>
import page from '@/mixins/page';
import date from '@/mixins/date';
import SectionAddress from './SectionAddress';
import ProfessionalOrderCondition from '../form/ProfessionalOrderCondition';

export default {
  name: 'PageShareholder',
  mixins: [
    page('shareholder'),
    date
  ],
  components: {
    ProfessionalOrderCondition,
    SectionAddress
  },
  data () {
    return {
      rerender: 0
    };
  },
  computed: {
    hasOneShareholder () {
      return this.model?.length === 1;
    },
    isMissingShareType () {
      return this.errors.includes('missingShareType');
    },
  },
  methods: {
    clearAllErrors (index) {
      this.clearErrors();
      this.$refs[`shareholderAddress${index}`][0]?.clearErrors();
    },
    addShareholder () {
      this.model.push({
        individual: true,
        firstname: '',
        lastname: '',
        birthdate: undefined,
        phone: '',
        contactname: '',
        contactphone: '',
        corporationname: '',
        neq: '',
        address: '',
        city: '',
        postalcode: '',
        province: 'QC',
        votingSharesPercentage: 0,
        participatingSharesPercentage: 0,
        isVotingShares: false,
        isParticipatingShares: false
      });

      this.updateShareDistribution();
    },
    removeShareholder (index) {
      this.model.splice(index, 1);
      this.removeError(`birthdate${index}`);
      this.removeError(`firstname${index}`);
      this.removeError(`lastname${index}`);
      this.removeError(`phone${index}`);
      this.removeError(`contactname${index}`);
      this.removeError(`contactphone${index}`);
      this.removeError(`corporationname${index}`);

      if (this.model.length === 1) {
        this.model[0].isVotingShares = true;
        this.model[0].isParticipatingShares = true;
      }

      this.updateShareDistribution();
    },
    updateShareDistribution () {
      const numberOfParticipatingShareholders = this.model.filter(shareholder => shareholder.isParticipatingShares).length;
      const numberOfVotingShareholders = this.model.filter(shareholder => shareholder.isVotingShares).length;
      this.model.forEach(shareholder => {
        if (shareholder.isVotingShares) {
          shareholder.votingSharesPercentage = Math.floor(100 / numberOfVotingShareholders);
        } else {
          shareholder.votingSharesPercentage = 0;
        }

        if (shareholder.isParticipatingShares) {
          shareholder.participatingSharesPercentage = Math.floor(100 / numberOfParticipatingShareholders);
        } else {
          shareholder.participatingSharesPercentage = 0;
        }
      });
    },
    rerenderArray () {
      this.rerender += this.model.length;
    },
    useDirectors () {
      document.activeElement?.blur();
      const fields = ['firstname', 'lastname', 'birthdate', 'address', 'city', 'province', 'postalcode', 'phone'];
      fields.forEach(key => {
        this.storedModel.director.forEach((director, index) => {
          if (!this.model[index]) {
            this.addShareholder();
          }
          this.model[index].individual = true;
          this.model[index][key] = director[key];
          this.validateBirthdate(index);
          this.validateFirstname(index);
          this.validateLastname(index);
          this.validatePhone(index);
        });
      });
      this.rerenderArray();
    },
    validate () {
      this.validateShares();
      this.validateIndividuals();
    },
    validateShares () {
      const hasVotingShares = this.model.find(shareholder => shareholder.isVotingShares);
      const hasParticipatingShares = this.model.find(shareholder => shareholder.isParticipatingShares);

      if (!(hasVotingShares && hasParticipatingShares))
        this.addError('missingShareType');
      else
        this.removeError('missingShareType');
    },
    validateIndividuals () {
      this.model.forEach((shareholder, index) => {
        if (shareholder.individual) {
          this.validateBirthdate(index);
          this.validateFirstname(index);
          this.validateLastname(index);
          this.validatePhone(index);
        } else {
          this.validateCorporationName(index);
          this.validateContactName(index);
          this.validateContactPhone(index);
        }

        const addressErrors = this.$refs[`shareholderAddress${index}`][0]?.validate();
        if (addressErrors?.length)
          this.addError(`shareholderAddress${index}`);
        else
          this.removeError(`shareholderAddress${index}`);
      });
    },
    validateBirthdate (index) {
      if (!this.model[index].birthdate)
        this.addError(`birthdate${index}`);
      else
        this.removeError(`birthdate${index}`);
    },
    validateCorporationName (index) {
      this.validateNotEmpty(`corporationname${index}`, this.model[index].corporationname);
    },
    validateContactName (index) {
      this.validateNotEmpty(`contactname${index}`, this.model[index].contactname);
    },
    validateContactPhone (index) {
      if (!this.hasIncorporationAccess) this.validateMinLength(`contactphone${index}`, this.model[index].contactphone, 10);
    },
    validateFirstname (index) {
      this.validateNotEmpty(`firstname${index}`, this.model[index].firstname);
    },
    validateLastname (index) {
      this.validateNotEmpty(`lastname${index}`, this.model[index].lastname);
    },
    validatePhone (index) {
      if (!this.hasIncorporationAccess) this.validateMinLength(`phone${index}`, this.model[index].phone, 10);
    }
  }
};
</script>
